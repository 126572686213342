<template>
  <div>
    <b-modal
      id="LoadFilesModal"
      title="Cargar Archivos"
      @hidden="resetFormModal"
      hide-footer
      hide-header-close
    >
      <form id="formUpload" ref="formUpload" v-on:submit="this.handleSubmit">
        <input type="hidden" v-model="form.document_type_id" />
        <input type="hidden" v-model="form.developer_id" />

        <div class="row">
          <div class="col-8 input-group">
            <input
              type="text"
              readonly
              class="form-control input-group"
              ref="text"
            />
            <button
              v-on:click="loadFile"
              class="btn btn-primary input-group-text"
              type="button"
            >
              <i class="fa fa-upload"> </i> Cargar Documento
            </button>
          </div>
        </div>

        <div style="text-align: right; margin-top: 30px">
          <a
            @click="
              $root.$emit('bv::hide::modal', 'LoadFilesModal', '#btnShow')
            "
            class="btn btn-secondary mx-2"
          >
            <i class="fa fa-times"> </i> Cancelar
          </a>
          <button type="submit" class="btn btn-primary">
            <i class="fa fa-save"> </i> Guardar
          </button>
        </div>

        <!-- Styled -->
        <input
          type="file"
          id="name"
          ref="name"
          v-on:change="handleFilesUpload()"
        />
      </form>
    </b-modal>
  </div>
</template>
<script>
import { uploadDocuments } from "../../../services/DocumentsAPI.js";
import Swal from "sweetalert2";

export default {
  props: {
    document_type_id: Number,
    developer_id: Number,
  },
  setup() {},
  data() {
    return {
      form: {
        name: null,
        document_type_id: 0,
        developer_id: 0,
      },
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    loadFile() {
      this.$refs.name.click();
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.name.files;
      this.$refs.text.value = uploadedFiles[0].name;

      this.form.name = uploadedFiles[0];
      this.form.document_type_id = this.document_type_id;
      this.form.developer_id = this.developer_id;
    },
    async handleSubmit(e) {
      e.preventDefault();

      var form_data = new FormData();

      form_data.append("name", this.form.name);
      form_data.append("document_type_id", this.form.document_type_id);
      form_data.append("developer_id", this.form.developer_id);
      form_data.append("project_id", this.projectId);
      await uploadDocuments(form_data)
        .then((data) => {
          Swal.fire({
            title: "¡Proceso exitoso!",
            text: data,
            icon: "success",
            confirmButtonText: "Listo",
          }).then(() => {
            this.$root.$emit("bv::hide::modal", "LoadFilesModal", "#btnShow");
            this.$emit("refreshDocuments");
          });
        })
        .catch(() => {
          Swal.fire({
            title: "¡Error al intentar enviar el formulario!",
            text: "Favor verifique si todos los campos han sido completados",
            icon: "error",
            confirmButtonText: "Volver",
          });
        });
    },
    resetFormModal() {
      this.form = {
        name: null,
        document_type_id: 0,
        developer_id: 0,
      };
    },
  },
};
</script>

<style>
input[type="file"] {
  position: absolute;
  top: -500px;
}
</style>
