import { api } from "../core/api";

export default {
  getPersonType() {
    return api.get("/api/person-type");
  },
  getRecreationAreaEquipment() {
    return api.get("/api/project/recreation-area/equipments/all");
  },
  getTypologyTypes() {
    return api.get("/api/trypology-types/all");
  },
  getDGIIInformation(rnc) {
    return api.get("/api/dgii/get-rnc/" + rnc);
  },
};
