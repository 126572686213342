<template>
  <div>
    <Tabs :activeTab="0" />
    <form @submit.prevent="handleSubmit" id="developerDataForm">
      <div class="content-wrap-container">
        <div class="row">
          <div>
            <div>
              <div class="col-3 form-group">
                <label for="rnc">RNC</label>
                <input
                  type="text"
                  id="rnc"
                  v-mask="'#-##-#####-#'"
                  class="form-control"
                  v-model="form.rnc"
                  @change="getRNCInformation()"
                  :readonly="true"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-3 form-group">
                <label for="business_name">Nombre / Razon Social</label>
                <input
                  type="text"
                  id="business_name"
                  class="form-control"
                  v-model="form.business_name"
                  :readonly="true"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-6 form-group">
                <label for="address">Dirección</label>
                <input
                  type="text"
                  id="address"
                  class="form-control"
                  v-model="form.address"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
            </div>
            <div>
              <div class="col-4 form-group">
                <label for="state">Provincia</label>
                <input
                  type="text"
                  id="state"
                  class="form-control"
                  v-model="form.state"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-4 form-group">
                <label for="legal_representation">Representante Legal</label>
                <input
                  type="text"
                  id="legal_representation"
                  class="form-control"
                  v-model="form.legal_representation"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-4 form-group">
                <label for="position">Cargo</label>
                <input
                  type="text"
                  id="position"
                  class="form-control"
                  v-model="form.position"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
            </div>
            <div>
              <div class="col-4 form-group">
                <label for="email">Correo electrónico</label>
                <input
                  type="text"
                  id="email"
                  class="form-control"
                  v-model="form.email"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-4 form-group">
                <label for="total_assets">Total activos</label>
                <input
                  type="text"
                  id="total_assets"
                  class="form-control"
                  v-mask="currencyMask"
                  v-model="form.total_assets"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-4 form-group">
                <label for="current_assets">Activo corriente</label>
                <input
                  type="text"
                  id="current_assets"
                  class="form-control"
                  v-model="form.current_assets"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
            </div>
            <div>
              <div class="col-3 form-group">
                <label for="total_liabilities">Pasivo total (RD$)</label>
                <input
                  type="text"
                  id="total_liabilities"
                  class="form-control"
                  v-mask="currencyMask"
                  v-model="form.total_liabilities"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-3 form-group">
                <label for="heritage">Patrimonio (RD$)</label>
                <input
                  type="text"
                  id="heritage"
                  class="form-control"
                  v-mask="currencyMask"
                  v-model="form.heritage"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-3 form-group">
                <label for="dgii_status">Estado ante DGII</label>
                <input
                  type="text"
                  id="dgii_status"
                  class="form-control"
                  v-model="form.dgii_status"
                  :readonly="true"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-3 form-group">
                <label for="person_type_id">Tipo de persona</label>
                <select
                  v-model="form.person_type_id"
                  class="form-control"
                  id="person_type_id"
                  :readonly="isReadonly"
                >
                  <option value="">Seleccione una opcion</option>
                  <option
                    v-for="(type, index) in personTypes"
                    :key="index"
                    :value="type.id"
                  >
                    {{ type.name }}
                  </option>
                </select>
                <span class="error d-none"></span>
              </div>
            </div>
            <div>
              <div class="col-3 form-group">
                <label for="phone1">Teléfono 1</label>
                <input
                  type="text"
                  id="phone1"
                  v-mask="'(###) ### ####'"
                  class="form-control"
                  v-model="form.phone1"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-3 form-group">
                <label for="phone2">Teléfono 2</label>
                <input
                  type="text"
                  id="phone2"
                  v-mask="'(###) ### ####'"
                  class="form-control"
                  v-model="form.phone2"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-3 form-group">
                <label for="office_phone">Teléfono Oficina</label>
                <input
                  type="text"
                  id="office_phone"
                  v-mask="'(###) ### ####'"
                  class="form-control"
                  v-model="form.office_phone"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-3 form-group">
                <label for="cellphone">Celular</label>
                <input
                  type="text"
                  id="cellphone"
                  class="form-control"
                  v-mask="'(###) ### ####'"
                  v-model="form.cellphone"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
            </div>
            <div>
              <div class="col-3 form-group">
                <label for="current_liabilities">Pasivo Corriente (RD$)</label>
                <input
                  type="text"
                  id="current_liabilities"
                  class="form-control"
                  v-mask="currencyMask"
                  v-model="form.current_liabilities"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-3 form-group">
                <label for="total_passive">Pasivo total (RD$)</label>
                <input
                  type="text"
                  id="total_passive"
                  class="form-control"
                  v-mask="currencyMask"
                  v-model="form.total_passive"
                  :readonly="isReadonly"
                />
                <span class="error d-none"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 my-4 text-end form-group">
            <b-button
              variant="outline-success"
              @click="enableDisableFields"
              class="mx-4 btn-success btn text-white"
              ><i class="fa fa-pencil"> </i> &nbsp; Editar</b-button
            >
            <b-button type="submit" variant="primary" :disabled="isReadonly">
              <i v-if="!isLoading" class="fa fa-save"> </i>
              <i v-else class="fa fa-spinner fa-spin"></i>
              &nbsp;Guardar
            </b-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <table class="table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nombre del documento</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(type, index) in chunkArray(documentTypes, 2)[0]"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td v-if="type.isUploaded">
                    <a
                      @click="downloadDocument(type.id, type.extension)"
                      style="cursor: pointer"
                      class="text-primary"
                    >
                      {{ type.name }}
                    </a>
                  </td>
                  <td v-if="!type.isUploaded">{{ type.name }}</td>
                  <td>
                    <i
                      @click="showModal(type.id)"
                      class="pointer fa fa-upload"
                    ></i>
                    &nbsp;
                    <i
                      class="pointer fa fa-trash"
                      v-on:click="deleteDocument(type.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nombre del documento</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(type, index) in chunkArray(documentTypes, 2)[1]"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td v-if="type.isUploaded" @click="downloadDocument(type.id)">
                    <a
                      @click="downloadDocument(type.id)"
                      style="cursor: pointer"
                      class="text-primary"
                    >
                      {{ type.name }}
                    </a>
                  </td>
                  <td v-if="!type.isUploaded">{{ type.name }}</td>
                  <td>
                    <i
                      @click="showModal(type.id)"
                      class="pointer fa fa-upload"
                    ></i>
                    &nbsp;
                    <i
                      class="pointer fa fa-trash"
                      v-on:click="deleteDocument(type.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <a href="" class="all-documents-download-button">
              <i class="fa fa-download"></i>
              <span>Descargar todos los archivos</span>
            </a>
          </div>
        </div>
      </div>
      <!--end content-wrap-container-->

      <!--end content wrap container-->
    </form>

    <file-modal-popup-developer
      @refreshDocuments="refreshDocuments"
      :developer_id="developer_id"
      :document_type_id="document_type_id"
    />
  </div>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});
import Tabs from "../../components/Project/Tabs.vue";
import SourceTypes from "@/constants/SourceTypes";
import generalAPI from "@/services/generalAPI";
import {
  getDeveloperData,
  getPersonTypes,
  saveDeveloperData,
  getDocumentTypes,
} from "../../services/developerData";
import { alertError, alertSuccess } from "../../helpers/alerts";
import FileModalPopupDeveloper from "./Components/FileModalPopupDeveloper.vue";
import { deleteDocumentTypes } from "../../services/DocumentsAPI.js";
import Swal from "sweetalert2";
import {
  getUploadedDocument,
  downloadDocument,
} from "../../services/DocumentsAPI";

export default {
  components: { Tabs, FileModalPopupDeveloper },
  data() {
    return {
      currencyMask,
      isLoading: false,
      isReadonly: true,
      documentTypes: [],
      personTypes: [],
      form: {
        id: 0,
        rnc: "",
        business_name: "",
        dgii_status: "",
        person_type_id: "",
        address: "",
        state: "",
        phone1: "",
        phone2: "",
        legal_representation: "",
        position: "",
        email: "",
        office_phone: "",
        cellphone: "",
        total_assets: 0,
        total_liabilities: 0,
        current_assets: 0,
        current_liabilities: 0,
        total_passive: 0,
        heritage: 0,
      },
      file1: null,
      developer_id: 0,
      document_type_id: 0,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    async getRNCInformation() {
      let { data } = await generalAPI.getDGIIInformation(
        this.form.rnc.replace(/-/g, "")
      );
      if (data.rnc.length > 0) {
        this.form.business_name = data.rnc[0].legal_name;
        this.form.dgii_status = data.rnc[0].dgii_status;
      }
    },
    async refreshDocuments() {
      await this.getDocumentType();
    },
    async handleSubmit() {
      this.isLoading = true;

      var form = { ...this.form };

      if (form.rnc != null) {
        form.rnc = form.rnc.replace(/-|\s/g, "");
      }

      if (form.phone1 != null) {
        form.phone1 = form.phone1.replace(/-|\s/g, "");
      }

      if (form.phone2 != null) {
        form.phone2 = form.phone2.replace(/-|\s/g, "");
      }

      if (form.cellphone != null) {
        form.cellphone = form.cellphone.replace(/-|\s/g, "");
      }

      if (form.office_phone != null) {
        form.office_phone = form.office_phone.replace(/-|\s/g, "");
      }

      let response = await saveDeveloperData(form).catch((error) => {
        this.isLoading = false;
        var validationErrors = error.response.data.errors;
        alertError("Error al guardar los datos.");
        this.isLoading = false;
        document.querySelectorAll(".error").forEach((item) => {
          item.classList.add("d-none");
        });
        Object.keys(validationErrors).forEach((key) => {
          var item = document.querySelector(`#${key}`);
          var next = item.nextElementSibling;
          next.textContent = validationErrors[key][0];
          next.classList.remove("d-none");
        });
      });

      if (typeof response.success !== "undefined" && response.success) {
        alertSuccess("Datos guardados correctamente.");
        this.isLoading = false;
        document.querySelectorAll(".error").forEach((item) => {
          item.classList.add("d-none");
        });
        this.enableDisableFields();
      } else {
        alertError("Error al guardar los datos.");
        this.isLoading = false;
        document.querySelectorAll(".error").forEach((item) => {
          item.classList.add("d-none");
        });
      }
    },
    resetForm() {},
    async getDevData() {
      let data = await getDeveloperData().catch(() => {
        alertError("Error al obtener los datos del desarrollador.");
      });
      this.form = data;
    },
    async getPersonType() {
      let data = await getPersonTypes().catch(() => {
        alertError("Error al obtener los tipos de persona.");
      });
      this.personTypes = data;
    },
    async getDocumentType() {
      let data = await getDocumentTypes().catch(() => {
        alertError("Error al obtener los tipos de documentos.");
      });
      let documents = data.filter(
        (x) => x.source_type_category_id == SourceTypes.DatosDelDesarrollador
      );
      this.documentTypes = await this.getUploadedDocument(documents);
    },
    enableDisableFields() {
      this.isReadonly = !this.isReadonly;
    },

    chunkArray(arr, n) {
      var chunkLength = Math.max(arr.length / n, 1);
      var chunks = [];
      for (var i = 0; i < n; i++) {
        if (chunkLength * (i + 1) <= arr.length)
          chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
      }
      return chunks;
    },
    showModal(documentIndex) {
      this.document_type_id = documentIndex;
      this.$root.$emit("bv::show::modal", "LoadFilesModal", "#btnShow");
      //alert(documentIndex);
      //alert();
    },
    async loadDeveloper() {
      let developer = await getDeveloperData();
      this.developer_id = developer.id;
    },
    async deleteDocument(documentId) {
      await Swal.fire({
        title: "Esta seguro que desea eliminar el archivo?",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteDocumentTypes(
            this.developer_id,
            documentId,
            this.projectId
          )
            .then(async (data) => {
              await this.refreshDocuments();
              Swal.fire({
                title: "¡Proceso exitoso!",
                text: data,
                icon: "success",
                confirmButtonText: "Listo",
              });
            })
            .catch((error) => {
              console.log("error", error);
              Swal.fire({
                title: "¡Error!",
                text: "Archivo no existe",
                icon: "error",
                confirmButtonText: "Volver",
              });
            });
        }
      });
    },
    async getUploadedDocument(documents) {
      var data = await getUploadedDocument(
        SourceTypes.DatosDelDesarrollador,
        this.projectId
      );
      documents.forEach((e) => {
        var document = data.documents.filter((x) => x.document_type_id == e.id);
        if (document.length > 0) {
          e.isUploaded = true;
          e.extension = document[0].extension;
        } else {
          e.isUploaded = false;
        }
      });
      return documents;
    },
    async downloadDocument(source_type, ext) {
      downloadDocument(source_type, ext, this.projectId);
    },
  },
  async mounted() {
    this.getDocumentType(), this.getDevData(), this.getPersonType();
    await this.loadDeveloper();
  },
};
</script>

<style scoped>
.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.pointer {
  cursor: pointer;
}
form .form-group {
  text-align: start;
}
table tr th,
table tr td {
  text-align: start;
}

table tr td:last-child {
  text-align: right !important;
}
.form-group {
  float: left;
  padding: 0 1%;
  padding-top: 15px;
}
div.form-group:first-child {
  padding-left: 0;
}
div.form-group:last-child {
  padding-right: 0;
}
</style>
